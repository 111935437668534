import React from 'react'
import { useQuery } from 'react-query'

import { serviceStatus } from '../../app/queries'
import { useService } from '../ServiceProvider/ServiceProvider'

import { CompletedStatus } from './CompletedStatus/CompletedStatus'
import { ErrorStatus } from './ErrorStatus/ErrorStatus'
import { InProgressStatus } from './InProgressStatus/InProgressStatus'

export const ServiceStatus = () => {
  const { serviceValues } = useService()
  const pollingTimeinMillis = 10000
  const finalStatuses = [
    'COMPLETE',
    'FAILED',
    'GITHUB_ERROR',
    'ECR_ERROR',
    'TEMPLATE_ERROR'
  ]

  const { isLoading, error, data } = useQuery(
    'serviceData',
    () => serviceStatus(serviceValues.serviceName),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (finalStatuses.includes(data)) {
          return false
        }
        return pollingTimeinMillis
      }
    }
  )

  var status = data
  if (isLoading) status = `REQUEST_RECEIVED`
  if (error) return <ErrorStatus errorMessage={error.message} />
  if (!status) return <ErrorStatus />

  if (finalStatuses.includes(status)) {
    return <CompletedStatus status={status} serviceValues={serviceValues} />
  }

  return <InProgressStatus status={status} serviceValues={serviceValues} />
}
