import React from 'react'

import { ModalFooter } from '@toasttab/buffet-pui-modal'
import { LinearProgressBar } from '@toasttab/buffet-pui-progress-indicators'
import { CancelButton } from '@toasttab/buffet-pui-forms'
import {
  getServiceProgressPercent,
  getServiceStatusString
} from '../service-status-helpers'
import { LilypadModalBody } from '../../LilypadModalBody/LilypadModalBody'

export const InProgressStatus = ({ status, serviceValues }) => {
  return (
    <>
      <LilypadModalBody>
        {getServiceStatusString(status, serviceValues)}
        <div className='pt-5'>
          <LinearProgressBar
            progressPercent={getServiceProgressPercent(status, serviceValues)}
            size='md'
            testId='linear-progress-bar'
          />
        </div>
      </LilypadModalBody>
      <ModalFooter>
        <CancelButton onClick={() => (window.location = '/')}>
          Close
        </CancelButton>
      </ModalFooter>
    </>
  )
}
