import React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'

const BaseConfigField = (props) => <TextInputField {...props} />

export const ServiceNameField = () => (
  <BaseConfigField
    name='serviceName'
    label='Desired service name'
    placeholder='toast-drones'
  />
)

export const ClassNameField = () => (
  <BaseConfigField name='className' label='Class name' placeholder='Drones' />
)

export const PackageNameField = () => (
  <BaseConfigField
    name='packageName'
    label='Package name'
    placeholder='drones'
  />
)

export const GithubUsernameField = () => (
  <BaseConfigField
    name='githubUsername'
    label='Github username'
    placeholder='sfredette'
  />
)
