import React from 'react'
import { RadioGroupField } from '@toasttab/buffet-pui-forms'
import { useFormikContext } from 'formik'

export const ServiceSelector = () => (
  <RadioGroupField
    label='Flavor of service'
    name='serviceType'
    testId='service-type'
    options={[
      { value: 'G2', label: 'G2 Service' },
      { value: 'G3', label: 'G3 Service', disabled: true },
      { value: 'SPA', label: 'Single Page App (SPA)', disabled: true },
      { value: 'BFF', label: 'Backend for Frontend (BFF)', disabled: true },
      { value: 'CAMEL', label: 'Camel', disabled: true }
    ]}
  />
)

export const DBSelector = () => {
  const formik = useFormikContext()
  let serviceSpecificOptions
  switch (formik.values.serviceType) {
    case 'BFF':
      serviceSpecificOptions = [
        { value: 'bff-mysql', label: 'MySQL' },
        { value: 'bff-nosql', label: 'NoSQL (Dynamo)' },
        { value: 'cockroach', label: 'Cockroach DB' },
        { value: 'NONE', label: 'None' }
      ]
      break
    default:
      serviceSpecificOptions = [
        { value: 'NONE', label: 'None', disabled: true },
        { value: 'DYNAMODB', label: 'NoSQL (Dynamo)' },
        { value: 'POSTGRES', label: 'Postgresql', disabled: true },
        { value: 'SQL', label: 'SQL (MySQL)', disabled: true }
      ]
  }

  return (
    <RadioGroupField
      label='Database type'
      name='dbType'
      testId='database-type'
      options={serviceSpecificOptions}
    />
  )
}
