import React from 'react'
import { ModalFooter } from '@toasttab/buffet-pui-modal'
import { CancelButton } from '@toasttab/buffet-pui-forms'
import { LilypadModalBody } from '../../LilypadModalBody/LilypadModalBody'

export const ErrorStatus = ({ errorMessage }) => {
  return (
    <>
      <LilypadModalBody>
        An unexpected error has occurred
        {errorMessage ?? `: ${errorMessage}`}
      </LilypadModalBody>
      <ModalFooter>
        <CancelButton onClick={() => (window.location = '/')}>OK</CancelButton>
      </ModalFooter>
    </>
  )
}
