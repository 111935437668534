import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'

import { Button } from '@toasttab/buffet-pui-buttons'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'

import { createService } from '../app/queries'
import { ServiceNameField, GithubUsernameField } from './InputFields'
import { ServiceSelector } from './ServiceOptionSelectors'
import { ProgressModal } from './ProgressModal'
import { useService } from './ServiceProvider/ServiceProvider'
import { ServiceOptions } from './ServiceOptions'
import { useMutation } from 'react-query'
import { validationSchema } from './FormValidationSchema'

export const CreateServiceForm = () => {
  const mutation = useMutation(createService)
  const { setServiceValues } = useService()

  const cancel = () => (window.location = '/')
  const appSubmit = async (values) => {
    setServiceValues(values)
    await mutation.mutateAsync(values).catch(() => {
      /* do nothing, error has already been displayed 
      to the user from mutation.isError being true  */
    })
  }

  const PRITUNL_HINT_TEXT = 'Check pritunl connection.'
  const SHOW_PRITUNL_HINT_AFTER_TIME_IN_MILLIS = 10 * 1000
  const [hint, setHint] = useState(false)

  useEffect(() => {
    let timeout
    if (mutation.isLoading) {
      timeout = setTimeout(() => {
        setHint(PRITUNL_HINT_TEXT)
      }, SHOW_PRITUNL_HINT_AFTER_TIME_IN_MILLIS)
      return () => clearTimeout(timeout)
    } else {
      setHint(null)
    }
  }, [mutation.isLoading, SHOW_PRITUNL_HINT_AFTER_TIME_IN_MILLIS])

  if (mutation.isSuccess) return <ProgressModal />

  return (
    <div className='h-screen w-screen'>
      <Formik
        initialValues={{
          serviceName: '',
          className: '',
          packageName: '',
          githubUsername: '',
          serviceType: '',
          dbType: 'none'
        }}
        validationSchema={validationSchema}
        onSubmit={appSubmit}
      >
        <Form id='create-service' autoComplete='false'>
          <Modal
            parentSelector={() =>
              document.getElementsByClassName(
                'single-spa-application:dx-lilypad'
              )[0]
            }
            onRequestClose={cancel}
            isOpen
          >
            <ModalHeader>Create</ModalHeader>
            <ModalBody>
              <div className='flex flex-col space-y-4 text-default'>
                <ServiceNameField />
                <ServiceSelector />
                <ServiceOptions />
                <GithubUsernameField />
              </div>
              <div className='h-4'></div>
            </ModalBody>
            <ModalFooter>
              {mutation.isLoading && (
                <>
                  <span>{hint}</span>
                  <div className='flex-none'>
                    <MerryGoRound size='xxs' />
                  </div>
                </>
              )}
              {mutation.isError && (
                <div className='flex-grow sm:flex-none'>
                  <p>Lotus request failed.</p>
                  <p>{PRITUNL_HINT_TEXT}</p>
                </div>
              )}
              <SubmitButton
                form='create-service'
                className='flex-grow sm:flex-none'
              />
              <Button
                className='flex-grow sm:flex-none'
                variant='link'
                onClick={cancel}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Form>
      </Formik>
    </div>
  )
}
