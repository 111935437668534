import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  serviceName: yup
    .string()
    .matches(/^toast-/, "Service name must begin with 'toast-' for now")
    .required('This field is required'),
  className: yup
    .string()
    .matches(/^[A-Z]/, 'Class name must begin with a capital letter')
    .when('serviceType', {
      is: 'G2',
      then: yup.string().required('This field is required')
    }),
  packageName: yup
    .string()
    .matches(/^[a-z]/, 'Package name must start with a lowercase letter')
    .when('serviceType', {
      is: 'G2',
      then: yup.string().required('This field is required')
    }),
  githubUsername: yup
    .string()
    .matches(/^((?!@).)*$/, 'Github username should not be an e-mail')
    .required('This field is required'),
  serviceType: yup.string().required('This field is required')
})
