import { useFormikContext } from 'formik'
import React from 'react'
import { DBSelector } from '../components/ServiceOptionSelectors'
import { ClassNameField, PackageNameField } from './InputFields'

export const ServiceOptions = () => {
  const formik = useFormikContext()
  switch (formik.values.serviceType) {
    case 'G2':
      return (
        <>
          <ClassNameField />
          <PackageNameField />
          <DBSelector />
        </>
      )
    case 'SPA':
      return null
    default:
      return <DBSelector />
  }
}
