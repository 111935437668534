import React from 'react'
import { ModalFooter } from '@toasttab/buffet-pui-modal'
import { IconLinkButton } from '@toasttab/buffet-pui-buttons'
import { LinkIcon } from '@toasttab/buffet-pui-icons'
import { CancelButton } from '@toasttab/buffet-pui-forms'
import { getServiceStatusString } from '../service-status-helpers'
import { LilypadModalBody } from '../../LilypadModalBody/LilypadModalBody'

export const CompletedStatus = ({ status, serviceValues }) => {
  return (
    <>
      <LilypadModalBody>
        {getServiceStatusString(status, serviceValues)}
        {status === 'COMPLETE' && (
          <IconLinkButton
            href={`https://github.com/toasttab/${serviceValues.serviceName}`}
            icon={<LinkIcon />}
          />
        )}
      </LilypadModalBody>
      <ModalFooter>
        <CancelButton onClick={() => (window.location = '/')}>OK</CancelButton>
      </ModalFooter>
    </>
  )
}
