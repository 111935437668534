import React, { useState, useMemo } from 'react'

const ServiceContext = React.createContext({
  values: {}
})

export const ServiceProvider = ({ children }) => {
  const [serviceValues, setServiceValues] = useState()
  const context = useMemo(() => ({ serviceValues, setServiceValues }), [
    serviceValues
  ])
  return (
    <ServiceContext.Provider value={context}>
      {children}
    </ServiceContext.Provider>
  )
}

export const useService = () => React.useContext(ServiceContext)
