import React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { ServiceStatus } from './ServiceStatus/ServiceStatus'

export const ProgressModal = () => {
  return (
    <Modal
      parentSelector={() =>
        document.getElementsByClassName('single-spa-application:dx-lilypad')[0]
      }
      isOpen
    >
      <ServiceStatus />
    </Modal>
  )
}
